angular.module('cpformplastApp')
  .controller('InfoOrderModalController', function ($scope, Notification, DataManager, order) {

    $scope.order = order;

    $scope.init = function() {
      if ($scope.order._id) {
        DataManager.fetchOrder($scope.order._id).then(function(order){
          $scope.order = order;
          for (var i=0 ; i<$scope.order.suborders.length ; i++) {
            $scope.order.suborders[i].deliveryDate = new Date($scope.order.suborders[i].deliveryDate);
          }
        },function(error){
          console.log(error);
        });
      }
    };

    $scope.getSubordersTotal = function() {
      var total = 0;
      var subtotal = 0;
      for (var i=0 ; i<$scope.order.suborders.length ; i++) {
        if ($scope.order.suborders[i]._id) {
          total += $scope.order.suborders[i].quantity;
        }
        if (!$scope.order.suborders[i].toDelete) {
          subtotal += $scope.order.suborders[i].quantity;
        }
      }
      return Number(subtotal).toLocaleString() + '/' + Number(total).toLocaleString() + ' lbs';
    };

    $scope.init();
});
